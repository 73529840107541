.fa32ced4-range-slider * {
	box-sizing: border-box;
	padding: 0px;
	margin: 0px;
}
.fa32ced4-range-slider {
	display: flex;
	position: relative;
	border: solid 1px gray;
	border-radius: 10px;
	padding: 20px 10px;
	/* box-shadow: 1px 1px 4px black; */
	flex-direction: column;
	-webkit-touch-callout: none; /* iOS Safari */
	-webkit-user-select: none; /* Safari */
	-khtml-user-select: none; /* Konqueror HTML */
	-moz-user-select: none; /* Old versions of Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
	user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge,*/
}
.fa32ced4-range-slider .bar {
	display: flex;
}
.fa32ced4-range-slider .bar-left {
	width: 25%;
	background-color: lightgray;
	border-radius: 10px 0px 0px 10px;
	/* box-shadow: inset 0px 0px 5px black; */
	padding: 4px 0px;
}
.fa32ced4-range-slider .bar-right {
	width: 25%;
	background-color:lightgray;
	border-radius: 0px 10px 10px 0px;
	/* box-shadow: inset 0px 0px 5px black; */
}
.fa32ced4-range-slider .bar-inner {
	/* background-color: lime; */
	display: flex;
	flex-grow: 1;
	flex-shrink: 1;
	justify-content: space-between;
	position: relative;
	/* border: solid 1px black; */
	justify-content: space-between;
	/* box-shadow: inset 0px 0px 5px black; */
}
.fa32ced4-range-slider .bar-inner-left {
	width: 50%;
}
.fa32ced4-range-slider .bar-inner-right {
	width: 50%;
}
.fa32ced4-range-slider .thumb {
	background-color: white;
	position: relative;
	z-index: 1;
	cursor: pointer;
}
.fa32ced4-range-slider .thumb::before {
	content: '';
	background-color: #ACA19A;
	position: absolute;
	width: 20px;
	height: 20px;
	/* border: solid 1px black; */
	/* box-shadow: 0px 0px 3px black, inset 0px 0px 5px gray; */
	border-radius: 50%;
	z-index: 1;
	margin: -8px -12px;
	cursor: pointer;
}
.fa32ced4-range-slider .input-type-range:focus + .thumb::after {
	content: '';
	position: absolute;
	top: -4px;
	left: -7px;
	width: 11px;
	height: 11px;
	z-index: 2;
	border-radius: 50%;
	border: dotted 1px black;
	/* box-shadow: 0px 0px 5px white, inset 0px 0px 10px black; */
}
.fa32ced4-range-slider .caption {
	position: absolute;
	bottom: 35px;
	width: 2px;
	height: 2px;
	left: 1px;
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: visible;
	display: none;
}
.fa32ced4-range-slider .thumb .caption * {
	position: absolute;
	min-width: 30px;
	height: 30px;
	font-size: 75%;
	text-align: center;
	line-height: 30px;
	background-color: transparent;
	border-radius: 15px;
	color: transparent;
	/* box-shadow: 0px 0px 5px black; */
	padding: 0px 5px;
	white-space: nowrap;
}
.fa32ced4-range-slider .thumb:active .caption {
	display: flex;
}
.fa32ced4-range-slider .input-type-range:focus + .thumb .caption {
	display: flex;
}
.fa32ced4-range-slider .input-type-range {
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	opacity: 0;
	pointer-events: none;
}

.fa32ced4-range-slider .ruler {
	margin: 10px 0px -5px 0px;
	display: flex;
	/* display: none; */
	overflow: hidden;
}
.fa32ced4-range-slider .ruler .ruler-rule {
	border-left: solid 1px;
	border-bottom: solid 1px;
	display: flex;
	flex-grow: 1;
	flex-shrink: 1;
	padding: 5px 0px;
}
.fa32ced4-range-slider .ruler .ruler-rule:last-child {
	border-right: solid 1px;
}

.fa32ced4-range-slider .ruler .ruler-sub-rule {
	border-left: solid 1px;
	/* border-bottom: solid 1px; */
	display: flex;
	flex-grow: 1;
	flex-shrink: 1;
	padding: 3px 0px;
	bottom: 0px;
	margin-bottom: -5px;
}
.fa32ced4-range-slider .ruler .ruler-sub-rule:first-child {
	border-left: none;
}

.fa32ced4-range-slider .labels {
	display: flex;
	justify-content: space-between;
	padding: 0px;
	margin-top: 10px;
	margin-bottom: -20px;
	/* display: none; */
}
.fa32ced4-range-slider .label {
	color: transparent;
	font-size: 80%;
	display: flex;
	width: 1px;
	justify-content: center;
}
.fa32ced4-range-slider .label:first-child {
	justify-content: start;
}
.fa32ced4-range-slider .label:last-child {
	justify-content: end;
}
.fa32ced4-range-slider.zero-ranage-margin .thumb-left {
	right: 12px;
}
.fa32ced4-range-slider.zero-ranage-margin .thumb-right {
	left: 8px;
}


/* Disabled */
.fa32ced4-range-slider.disabled {
	border: solid 1px rgb(200, 200, 200);
	box-shadow: 1px 1px 4px rgb(180, 180, 180);
	color:rgb(180, 180, 180);
}
.fa32ced4-range-slider .bar {
	display: flex;
}
.fa32ced4-range-slider.disabled .bar-left {
	background-color: #c9c9c9;
	box-shadow: inset 0px 0px 5px rgb(160, 160, 160);
}
.fa32ced4-range-slider.disabled .bar-right {
	background-color: #c9c9c9;
	box-shadow: inset 0px 0px 5px rgb(160, 160, 160);
}
.fa32ced4-range-slider.disabled .bar-inner {
	background-color: rgb(130 243 130);
	border: solid 1px rgb(149, 149, 149);
	box-shadow: inset 0px 0px 5px rgb(103, 103, 103);
}
.fa32ced4-range-slider.disabled .thumb {
	background-color: white;
}
.fa32ced4-range-slider.disabled .thumb::before {
	border: solid 1px rgb(200, 200, 200);
	box-shadow: 0px 0px 3px rgb(35, 35, 35), inset 0px 0px 5px gray;
}
.fa32ced4-range-slider.disabled .input-type-range:focus + .thumb::after {
	border: dotted 1px rgb(35, 35, 35);
	box-shadow: 0px 0px 5px white, inset 0px 0px 10px rgb(35, 35, 35);
}
.fa32ced4-range-slider.disabled .thumb .caption * {
	background-color: rgb(84, 84, 137);
	color: rgb(199, 199, 199);
	box-shadow: 0px 0px 5px rgb(35, 35, 35);
}


