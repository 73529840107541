body {
  margin: 0;
}

.loader {
  position: absolute;
  top: 40%;
  left: 40%;
  margin-left: 10%;
  transform: translate3d(-50%, -50%, 0);
}

.dot {
  width: 48px;
  height: 48px;
  background: #073b4c;
  border-radius: 100%;
  display: inline-block;
  animation: slide 1s infinite;
}

.dot:nth-child(1) {
  animation-delay: 0.1s;
  background: #f78c6b;
}

.dot:nth-child(2) {
  animation-delay: 0.2s;
  background: #ffd166;
}

.dot:nth-child(3) {
  animation-delay: 0.3s;
  background: #83d483;
}

.dot:nth-child(4) {
  animation-delay: 0.4s;
  background: #349e00;
}

.dot:nth-child(5) {
  animation-delay: 0.5s;
  background: #06d6a0;
}

@-moz-keyframes slide {
  0% {
    transform: scale(1);
  }

  50% {
    opacity: 0.3;
    transform: scale(2);
  }

  100% {
    transform: scale(1);
  }
}

@-webkit-keyframes slide {
  0% {
    transform: scale(1);
  }

  50% {
    opacity: 0.3;
    transform: scale(2);
  }

  100% {
    transform: scale(1);
  }
}

@-o-keyframes slide {
  0% {
    transform: scale(1);
  }

  50% {
    opacity: 0.3;
    transform: scale(2);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes slide {
  0% {
    transform: scale(1);
  }

  50% {
    opacity: 0.3;
    transform: scale(2);
  }

  100% {
    transform: scale(1);
  }
}